<template>
    <v-card class="pa-4">
        <v-card-title class="text-h5 mb-4">
            Migrar Encuestas
        </v-card-title>

        <v-card-text>
            <div v-if="!isProcessing && !isComplete" class="mb-4">
                <p class="mb-2">Este proceso migrará las encuestas al nuevo formato.</p>
                <p class="mb-2">Encontradas {{ totalSurveys }} encuestas para procesar.</p>
                <v-btn color="primary" :disabled="totalSurveys === 0" @click="migrateSurveys">
                    Iniciar Migración
                </v-btn>
            </div>

            <div v-if="isProcessing">
                <p class="mb-2">Procesando encuestas...</p>
                <p class="mb-2">Progreso: {{ processedCount }} / {{ totalSurveys }}</p>
                <v-progress-linear :value="(processedCount / totalSurveys) * 100" color="primary" height="25">
                    <template v-slot:default>
                        {{ Math.round((processedCount / totalSurveys) * 100) }}%
                    </template>
                </v-progress-linear>
            </div>

            <div v-if="isComplete" class="success--text">
                <p class="mb-2">✅ Todas las encuestas han sido migradas!</p>
                <p>Encuestas migradas: {{ migratedCount }}</p>
                <p>Errores: {{ errorCount }}</p>
            </div>

            <v-alert v-if="error" type="error" class="mt-4">
                {{ error }}
            </v-alert>
        </v-card-text>
    </v-card>
</template>

<script>
import { collection, query, getDocs, getFirestore, writeBatch, doc } from 'firebase/firestore'

export default {
    name: 'FixSurveySchema',

    data() {
        return {
            isProcessing: false,
            isComplete: false,
            error: null,
            totalSurveys: 0,
            processedCount: 0,
            migratedCount: 0,
            errorCount: 0
        }
    },

    async mounted() {
        await this.countSurveys()
    },

    methods: {
        async countSurveys() {
            try {
                const db = getFirestore()
                const surveysQuery = query(collection(db, 'surveys'))
                const snapshot = await getDocs(surveysQuery)
                this.totalSurveys = snapshot.size
            } catch (error) {
                console.error('Error counting surveys:', error)
                this.error = 'Error contando encuestas: ' + error.message
            }
        },

        calculateAverageRating(data) {
            let total = 0
            let count = 0
            data.forEach(q => {
                // Only count questions that should have ratings (first 4 questions)
                if (!q.last && typeof q.rating === 'number') {
                    total += q.rating
                    count++
                }
            })
            return count > 0 ? total / count : 0
        },

        async migrateSurveys() {
            this.isProcessing = true
            this.error = null
            this.processedCount = 0
            this.migratedCount = 0
            this.errorCount = 0

            try {
                const db = getFirestore()
                const surveysQuery = query(collection(db, 'surveys'))
                const snapshot = await getDocs(surveysQuery)

                // Process in batches of 500 (Firestore batch limit)
                const batchSize = 500
                let batch = writeBatch(db)
                let batchCount = 0

                for (const surveyDoc of snapshot.docs) {
                    try {
                        const oldData = surveyDoc.data()
                        const surveyData = oldData.data || []
                        const isOmitted = !surveyData.some(q => typeof q.rating === 'number')

                        // Calculate average rating
                        const averageRating = this.calculateAverageRating(surveyData)

                        // Extract question ratings
                        const questions = {
                            limpieza: isOmitted ? 0 : (surveyData[3]?.rating || 0),
                            atencion: isOmitted ? 0 : (surveyData[1]?.rating || 0),
                            clases: isOmitted ? 0 : (surveyData[0]?.rating || 0),
                            instalaciones: isOmitted ? 0 : (surveyData[2]?.rating || 0)
                        }

                        // Get comment from last question
                        const comment = surveyData[4]?.comment || null

                        // New survey format
                        const newData = {
                            date: oldData.date,
                            userId: oldData.user,
                            userName: oldData.userName || oldData.user,
                            averageRating,
                            questions,
                            sentiment: isOmitted ? null : (oldData.sentiment || null),
                            comment,
                            omitted: isOmitted,
                            seen: oldData.seen || true // Preserve seen status for sentiment function
                        }

                        batch.set(doc(db, 'surveys', surveyDoc.id), newData)
                        batchCount++
                        this.migratedCount++

                        if (batchCount === batchSize) {
                            await batch.commit()
                            batch = writeBatch(db)
                            batchCount = 0
                        }
                    } catch (error) {
                        console.error('Error processing survey:', error)
                        this.errorCount++
                    }

                    this.processedCount++
                }

                // Commit any remaining updates
                if (batchCount > 0) {
                    await batch.commit()
                }

                this.isComplete = true
            } catch (error) {
                console.error('Error migrating surveys:', error)
                this.error = 'Error migrando encuestas: ' + error.message
            } finally {
                this.isProcessing = false
            }
        }
    }
}
</script>