<template>
    <v-container fluid>
        <v-row>


            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="12" md="6">
                        <MarginsCard></MarginsCard>
                    </v-col>
                    <v-col cols="12" md="6">
                        <AssistancesCard></AssistancesCard>
                    </v-col>
                    <v-col cols="12" md="6">
                        <TotalUsersCard></TotalUsersCard>
                    </v-col>
                    <v-col cols="12" md="6">
                        <UsersCard></UsersCard>
                    </v-col>
                    <v-col cols="12" md="6">
                        <DisabledUsersCard></DisabledUsersCard>
                    </v-col>
                    <v-col cols="12" md="6">
                        <ReEnabledUsersCard></ReEnabledUsersCard>
                    </v-col>

                </v-row>
            </v-col>


            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="12" md="12">
                        <SatisfactionCard></SatisfactionCard>
                    </v-col>
                    <v-col cols="12" md="12">
                        <TodaySchedules></TodaySchedules>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>


    </v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, orderBy, limit, Timestamp } from 'firebase/firestore';
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
import MarginsCard from '@/components/dashboard/MarginsCard.vue';
import TodaySchedules from '@/components/reports/dashboard/TodaySchedules.vue';
import AssistancesCard from '@/components/dashboard/AssistancesCard.vue';
import UsersCard from '@/components/dashboard/UsersCard.vue';
import DisabledUsersCard from '@/components/dashboard/DisabledUsersCard.vue';
import ReEnabledUsersCard from '@/components/dashboard/ReEnabledUsersCard.vue';
import TotalUsersCard from '@/components/dashboard/TotalUsersCard.vue';
import SatisfactionCard from '@/components/dashboard/SatisfactionCard.vue';
export default {
    name: 'AdminDashboard',
    components: {
        apexchart: VueApexCharts,
        TodaySchedules,
        MarginsCard,
        AssistancesCard,
        UsersCard,
        DisabledUsersCard,
        ReEnabledUsersCard,
        TotalUsersCard,
        SatisfactionCard
    },
    data() {
        return {
            loading: true,
            search: '',
            timeRange: 'month',
            quickStats: [
                {
                    title: 'Total Users',
                    value: '0',
                    icon: 'mdi-account-group',
                    color: 'primary',
                    trend: 0
                },
                {
                    title: 'Active Sessions',
                    value: '0',
                    icon: 'mdi-dumbbell',
                    color: 'success',
                    trend: 0
                },
                {
                    title: 'Monthly Revenue',
                    value: '$0',
                    icon: 'mdi-currency-usd',
                    color: 'info',
                    trend: 0
                },
                {
                    title: 'New Users',
                    value: '0',
                    icon: 'mdi-account-plus',
                    color: 'warning',
                    trend: 0
                }
            ],
            chartOptions: {
                chart: {
                    type: 'area',
                    toolbar: { show: false }
                },
                stroke: { curve: 'smooth' },
                xaxis: { type: 'datetime' },
                tooltip: {
                    x: { format: 'dd MMM yyyy' }
                },
                theme: {
                    mode: this.$store.state.isDark ? 'dark' : 'light'
                }
            },
            series: [{
                name: 'Active Users',
                data: []
            }],
            pieOptions: {
                labels: ['Pase Libre', 'Socio', 'Gratis', 'Otros'],
                theme: {
                    mode: this.$store.state.isDark ? 'dark' : 'light'
                }
            },
            pieSeries: [],
            headers: [
                { text: 'Usuario', value: 'user' },
                { text: 'Actividad', value: 'activity' },
                { text: 'Estado', value: 'status' },
                { text: 'Fecha', value: 'date' }
            ],
            activities: [],
            recentAlerts: []
        };
    },
    methods: {
        formatDate(date) {
            if (!date) return '';
            const timestamp = date.seconds ? date.seconds * 1000 : date;
            return moment(timestamp).format('DD/MM/YYYY HH:mm');
        },
        getStatusColor(status) {
            switch (status) {
                case 'Completado': return 'success';
                case 'Pendiente': return 'warning';
                case 'Fallido': return 'error';
                default: return 'grey';
            }
        },
        getAlertIcon(type) {
            switch (type) {
                case 'checkin_no_reservation': return 'mdi-account-cancel';
                case 'reservation_not_used': return 'mdi-calendar-remove';
                case 'user_license_paused': return 'mdi-account-off';
                case 'late_checkin': return 'mdi-clock-alert';
                default: return 'mdi-alert';
            }
        },
        getAlertTitle(type) {
            switch (type) {
                case 'checkin_no_reservation': return 'Check-in sin reserva';
                case 'reservation_not_used': return 'Reserva no utilizada';
                case 'user_license_paused': return 'Licencia pausada';
                case 'late_checkin': return 'Check-in tardío';
                default: return 'Alerta';
            }
        },
        async fetchQuickStats() {
            const db = getFirestore();
            const now = new Date();
            const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
            const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);

            // Total Users
            const usersQuery = query(collection(db, 'users'), where('disabled', '==', false));
            const usersSnapshot = await getDocs(usersQuery);
            this.quickStats[0].value = usersSnapshot.size.toString();

            // Active Sessions (Today's workouts)
            const startOfDay = new Date(now.setHours(0, 0, 0, 0));
            const workoutsQuery = query(
                collection(db, 'workouts'),
                where('date', '>=', startOfDay)
            );
            const workoutsSnapshot = await getDocs(workoutsQuery);
            this.quickStats[1].value = workoutsSnapshot.size.toString();

            // Monthly Revenue
            const paymentsQuery = query(
                collection(db, 'payments'),
                where('createdAt', '>=', startOfMonth),
                where('status', '==', 'Confirmado')
            );
            const paymentsSnapshot = await getDocs(paymentsQuery);
            let totalRevenue = 0;
            paymentsSnapshot.forEach(doc => {
                totalRevenue += doc.data().total || 0;
            });
            this.quickStats[2].value = `$${totalRevenue.toLocaleString()}`;

            // New Users This Month
            const newUsersQuery = query(
                collection(db, 'users'),
                where('createdAt', '>=', startOfMonth)
            );
            const newUsersSnapshot = await getDocs(newUsersQuery);
            this.quickStats[3].value = newUsersSnapshot.size.toString();
        },
        async fetchUserTypeDistribution() {
            const db = getFirestore();
            const usersQuery = query(collection(db, 'users'), where('disabled', '==', false));
            const snapshot = await getDocs(usersQuery);

            const distribution = {
                paselibre: 0,
                socio: 0,
                gratis: 0,
                otros: 0
            };

            snapshot.forEach(doc => {
                const type = doc.data().type;
                if (type === 'paselibre') distribution.paselibre++;
                else if (type === 'socio') distribution.socio++;
                else if (type === 'gratis') distribution.gratis++;
                else distribution.otros++;
            });

            this.pieSeries = [
                distribution.paselibre,
                distribution.socio,
                distribution.gratis,
                distribution.otros
            ];
        },
        async fetchRecentAlerts() {
            const db = getFirestore();
            const alertsQuery = query(
                collection(db, 'alerts'),
                orderBy('date', 'desc'),
                limit(5)
            );
            const snapshot = await getDocs(alertsQuery);
            this.recentAlerts = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                severity: this.getAlertSeverity(doc.data().type)
            }));
        },
        getAlertSeverity(type) {
            switch (type) {
                case 'reservation_not_used': return 'error';
                case 'late_checkin': return 'warning';
                default: return 'info';
            }
        },
        async fetchActivityData() {
            const db = getFirestore();
            const startDate = this.getStartDate();

            // Fetch recent workouts
            const workoutsQuery = query(
                collection(db, 'workouts'),
                where('date', '>=', startDate),
                orderBy('date', 'desc'),
                limit(10)
            );

            const snapshot = await getDocs(workoutsQuery);
            this.activities = snapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    user: data.userName || 'Usuario',
                    activity: 'Entrenamiento',
                    status: data.completed ? 'Completado' : 'Pendiente',
                    date: data.date
                };
            });
        },
        getStartDate() {
            const now = new Date();
            switch (this.timeRange) {
                case 'week':
                    return new Date(now.setDate(now.getDate() - 7));
                case 'year':
                    return new Date(now.setFullYear(now.getFullYear() - 1));
                default: // month
                    return new Date(now.setMonth(now.getMonth() - 1));
            }
        },
        async fetchChartData() {
            const db = getFirestore();
            const startDate = this.getStartDate();

            const usersQuery = query(
                collection(db, 'users'),
                where('createdAt', '>=', startDate),
                orderBy('createdAt')
            );

            const snapshot = await getDocs(usersQuery);
            const userData = {};

            snapshot.forEach(doc => {
                const date = moment(doc.data().createdAt.toDate()).startOf('day');
                const dateStr = date.format('YYYY-MM-DD');
                userData[dateStr] = (userData[dateStr] || 0) + 1;
            });

            this.series[0].data = Object.entries(userData).map(([date, count]) => ({
                x: new Date(date).getTime(),
                y: count
            }));
        },
        async fetchData() {
            try {
                this.loading = true;
                /*  await Promise.all([
                     this.fetchQuickStats(),
                     this.fetchUserTypeDistribution(),
                     this.fetchRecentAlerts(),
                     this.fetchActivityData(),
                     this.fetchChartData()
                 ]); */
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            } finally {
                this.loading = false;
            }
        }
    },
    mounted() {
        this.fetchData();
    },
    watch: {
        timeRange() {
            this.fetchData();
        }
    }
};
</script>

<style scoped>
.v-card {
    transition: transform 0.2s;
}

.v-card:hover {
    transform: translateY(-2px);
}

.error {
    background-color: rgba(var(--v-error-base), 0.1);
}

.warning {
    background-color: rgba(var(--v-warning-base), 0.1);
}

.success {
    background-color: rgba(var(--v-success-base), 0.1);
}
</style>