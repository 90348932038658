<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" max-width="330"
                    min-width="330">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field filled hide-details="" v-model="dates" label="Seleccionar un rango de fechas"
                            readonly v-bind="attrs" v-on="on" prepend-inner-icon="mdi-calendar"></v-text-field>
                    </template>
                    <v-date-picker v-model="dates" range width="100%" scrollable
                        :min="dates.length > 0 ? dates[0] : null">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary darken-1" @click=" dates = []">Cancelar</v-btn>
                        <v-btn text color="primary darken-1"
                            @click="menu = false; fetchSurveyReport()">Confirmar</v-btn>

                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
                <v-switch v-model="showOmitted" label="Mostrar encuestas omitidas"
                    @change="fetchSurveyReport"></v-switch>
            </v-col>
        </v-row>

        <v-tabs v-model="tab" background-color="transparent" grow>
            <v-tab>
                <v-icon left>
                    mdi-chart-bar
                </v-icon>
                Resumen
            </v-tab>
            <v-tab>
                <v-icon left>
                    mdi-format-list-bulleted
                </v-icon>
                Detalle
            </v-tab>

            <v-tab-item>
                <v-data-table :headers="headers" :items="reportData" class="elevation-1 mt-4" hide-default-footer
                    :loading="loading" mobile-breakpoint="0">

                    <template v-slot:item.averageRating="{ item }">
                        <div v-if="item.question.indexOf(')') == -1">


                            <v-progress-circular :color="returnColor(item.averageRating)" :size="60" :width="6"
                                class="ma-2" :value="item.averageRating * 20">

                                <!-- show percent -->
                                <template>
                                    <span>{{ (item.averageRating * 20).toFixed(1) }} %</span>
                                </template>



                            </v-progress-circular>


                        </div>
                    </template>
                    <template v-slot:item.commentCount="{ item }">




                        <p color="warning" class="text-h6 rounde-lg mt-2" large v-if="item.question.indexOf(')') != -1">
                            <v-icon left>
                                mdi-comment
                            </v-icon>
                            {{ item.commentCount
                            }} Comentarios


                            <br v-if="item['Positivo']" />
                            <v-chip small color="green darken-1" v-if="item['Positivo']">
                                <v-icon left>
                                    mdi-emoticon-happy-outline
                                </v-icon>
                                {{ item['Positivo'] }} Positivos
                            </v-chip>
                            <br v-if="item['Neutral']" />
                            <v-chip small color="grey" v-if="item['Neutral']">
                                <v-icon left>
                                    mdi-emoticon-neutral-outline
                                </v-icon>
                                {{ item['Neutral'] }} Neutrales
                            </v-chip>

                            <br v-if="item['Negativo']" />
                            <v-chip small color="red darken-1" v-if="item['Negativo']">
                                <v-icon left>
                                    mdi-emoticon-sad-outline
                                </v-icon>
                                {{ item['Negativo'] }} Negativos
                            </v-chip>
                        </p>
                    </template>


                </v-data-table>
            </v-tab-item>

            <v-tab-item>

                <v-data-table :headers="surveyListHeaders" :items="surveyList" class="elevation-1 mt-4" sort-by="date"
                    sort-desc="true" :loading="loading" mobile-breakpoint="0">

                    <template v-slot:item.clases="{ item }">
                        <v-rating :value="item.clases" color="amber" dense half-increments readonly
                            size="14"></v-rating>
                    </template>

                    <template v-slot:item.atencion="{ item }">
                        <v-rating :value="item.atencion" color="amber" dense half-increments readonly
                            size="14"></v-rating>
                    </template>

                    <template v-slot:item.instalaciones="{ item }">
                        <v-rating :value="item.instalaciones" color="amber" dense half-increments readonly
                            size="14"></v-rating>
                    </template>

                    <template v-slot:item.limpieza="{ item }">
                        <v-rating :value="item.limpieza" color="amber" dense half-increments readonly
                            size="14"></v-rating>
                    </template>

                    <template v-slot:item.averageRating="{ item }">
                        <v-rating :value="item.averageRating" color="amber" dense half-increments readonly
                            size="14"></v-rating>
                    </template>

                    <template v-slot:item.sentiment="{ item }">
                        <v-chip small v-if="item.sentiment == 'Neutral'">
                            Neutral
                        </v-chip>
                        <v-chip small color="success" v-else-if="item.sentiment == 'Positivo'">
                            Positivo
                        </v-chip>
                        <v-chip small color="error" v-else-if="item.sentiment == 'Negativo'">
                            Negativo
                        </v-chip>
                    </template>

                    <template v-slot:item.omitted="{ item }">
                        <v-chip small :color="item.omitted === 'Omitida' ? 'grey' : 'success'"
                            :text-color="item.omitted === 'Omitida' ? 'white' : 'white'">
                            {{ item.omitted }}
                        </v-chip>
                    </template>

                </v-data-table>

            </v-tab-item>



        </v-tabs>
    </v-container>
</template>

<script>
import { collection, query, where, getDocs, getFirestore, orderBy } from "firebase/firestore";
import moment from "moment";
const db = getFirestore();

export default {
    data() {
        return {
            menu: false,
            dates: [],
            reportData: [],
            showOmitted: false,
            headers: [
                { text: 'Pregunta', value: 'question' },
                {
                    text: 'Promedio de satisfacción', value: 'averageRating',
                    sortable: false,
                }
            ],
            surveyList: [],
            surveyListHeaders: [
                { text: 'Usuario', value: 'userName' },
                { text: 'Fecha', value: 'date' },
                { text: 'Estado', value: 'omitted' },
                { text: 'Experiencia', value: 'clases' },
                { text: 'Atención', value: 'atencion' },
                { text: 'Instalaciones', value: 'instalaciones' },
                { text: 'Limpieza', value: 'limpieza' },
                { text: 'Promedio', value: 'averageRating' },
                { text: 'Comentario', value: 'comment' },
                { text: 'Sentimiento', value: 'sentiment' }
            ],
            tab: 0,
            loading: false,
            search: '',
            dialog: false,
            selectedSurvey: null,
            surveys: [],
        };
    },
    methods: {
        returnColor(rating) {
            let value = parseFloat(rating);
            if (value <= 1) {
                return "red darken-2";
            } else if (value <= 2) {
                return "orange darken-2";
            } else if (value <= 3) {
                return "grey";
            } else if (value <= 4) {
                return "warning darken-2";
            }
            else if (value <= 4.5) {
                return "warning darken-1";
            }

            else if (value <= 5) {
                return "primary";
            }
        },
        async fetchSurveyReport() {
            this.loading = true;
            let start, end;

            if (this.dates.length == 2) {
                start = new Date(this.dates[0] + " 00:00:00")
                end = new Date(this.dates[1] + " 23:59:59")
            } else {
                let date = moment()
                let startOfMonth = moment().startOf('month')

                start = new Date(startOfMonth.format('YYYY-MM-DD') + " 00:00:00")
                end = new Date(date.format('YYYY-MM-DD') + " 23:59:59")

                this.dates = [startOfMonth.format('YYYY-MM-DD'), date.format('YYYY-MM-DD')]
            }

            const surveysRef = collection(db, "surveys");
            const q = query(surveysRef, where("date", ">=", start), where("date", "<=", end));
            const querySnapshot = await getDocs(q);

            let surveyList = [];
            querySnapshot.forEach((doc) => {
                const survey = doc.data();
                if (this.showOmitted || !survey.omitted) {
                    surveyList.push(survey);
                }
            });

            if (surveyList.length > 0) {
                this.surveyListHeaders = [
                    { text: 'Usuario', value: 'userName' },
                    { text: 'Fecha', value: 'date' },
                    { text: 'Estado', value: 'omitted' },
                    { text: 'Experiencia', value: 'clases' },
                    { text: 'Atención', value: 'atencion' },
                    { text: 'Instalaciones', value: 'instalaciones' },
                    { text: 'Limpieza', value: 'limpieza' },
                    { text: 'Promedio', value: 'averageRating' },
                    { text: 'Comentario', value: 'comment' },
                    { text: 'Sentimiento', value: 'sentiment' }
                ];

                this.surveyList = surveyList.map((survey) => {
                    let userName = survey.userName;
                    let date = moment(survey.date.toDate()).format('DD/MM/YYYY HH:mm');
                    return {
                        userName,
                        date,
                        omitted: survey.omitted ? 'Omitida' : 'Completada',
                        clases: survey.questions?.clases || 0,
                        atencion: survey.questions?.atencion || 0,
                        instalaciones: survey.questions?.instalaciones || 0,
                        limpieza: survey.questions?.limpieza || 0,
                        averageRating: survey.averageRating || 0,
                        comment: survey.comment || '-',
                        sentiment: survey.sentiment || '-'
                    };
                });
            }

            // Calculate summary data for the report
            const validSurveys = surveyList.filter(s => !s.omitted);
            const summary = [
                { question: 'Experiencia con el servicio', field: 'clases' },
                { question: 'Atención recibida', field: 'atencion' },
                { question: 'Estado de los equipos', field: 'instalaciones' },
                { question: 'Limpieza del lugar', field: 'limpieza' }
            ].map(item => {
                const ratings = validSurveys
                    .map(s => s.questions?.[item.field] || 0)
                    .filter(r => r > 0);
                const comments = validSurveys.filter(s => s.comment);

                return {
                    question: item.question,
                    averageRating: (ratings.reduce((a, b) => a + b, 0) / (ratings.length || 1)).toFixed(2),
                    commentCount: comments.length,
                    Positivo: comments.filter(s => s.sentiment === 'Positivo').length,
                    Neutral: comments.filter(s => s.sentiment === 'Neutral').length,
                    Negativo: comments.filter(s => s.sentiment === 'Negativo').length
                };
            });

            this.reportData = summary;

            this.loading = false;
        },
        formatDate(date) {
            return moment(date.toDate()).format('DD/MM/YYYY HH:mm')
        },
        getSentimentColor(sentiment) {
            switch (sentiment) {
                case 'Positivo': return 'success'
                case 'Neutral': return 'grey'
                case 'Negativo': return 'error'
                default: return 'grey'
            }
        },
        async fetchSurveys() {
            this.loading = true
            try {
                const db = getFirestore()
                const surveysRef = collection(db, 'surveys')
                const q = query(surveysRef, orderBy('date', 'desc'))
                const querySnapshot = await getDocs(q)

                this.surveys = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }))
            } catch (error) {
                console.error('Error fetching surveys:', error)
            } finally {
                this.loading = false
            }
        },
        viewSurvey(survey) {
            this.selectedSurvey = survey
            this.dialog = true
        }
    },
    mounted() {
        this.fetchSurveyReport();
        this.fetchSurveys();
    },
};
</script>

<style scoped>
/* Add your styles here */
</style>
